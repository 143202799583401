




















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { getScreenSizeVariant } from '@/vue-app/utils/screen';
import AlmostCompletedViewModel
  from '@/vue-app/view-models/components/contract-savings/almost-completed-view-model';

@Component({
  name: 'AlmostCompleted',
})
export default class AlmostCompleted extends Vue {
  almost_completed_view_model = Vue.observable(new AlmostCompletedViewModel());

  @Watch('almost_completed_view_model.is_loading')
  onLoadChange(is_loading: boolean) {
    this.$emit('loadingInfo', is_loading);
  }

  async nextStep() {
    const save_step = await this.almost_completed_view_model.saveStep();
    if (save_step) {
      const create_policy = await this.almost_completed_view_model.createPolicy();
      if (create_policy) {
        this.$emit('nextStep');
      }
    }
  }

  prevStep() {
    this.$emit('prevStep');
  }

  async created() {
    this.almost_completed_view_model.small_screen = getScreenSizeVariant() === 'small';
    await this.almost_completed_view_model.initialize();
  }
}

