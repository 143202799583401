import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';

// Application
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';
import MyInvestmentAllianzOnBoardingStatusSubscription
  from '@/modules/my-investment/allianz/on-boarding-status/application/subscriptions/my-investment-allianz-on-boarding-status-subscription';
import {
  CreateAllianzPolicyCommand,
} from '@/modules/my-investment/allianz/policy/application/commands';
import {
  SearchCustomerDocumentsQuery,
} from '@/modules/my-investment/customer-documents/application/queries';

// Domain
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import {
  AllianzPolicyEntity,
} from '@/modules/my-investment/allianz/policy/domain/entities/allianz-policy-entity';
import Inject from '@/modules/shared/domain/di/inject';

export default class AlmostCompletedViewModel {
  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.MY_INVESTMENT_ALLIANZ_ON_BOARDING_STATUS_SUBSCRIPTION)
  private readonly my_investment_allianz_on_boarding_status_subscription!:
    MyInvestmentAllianzOnBoardingStatusSubscription;

  @Inject(TYPES.CREATE_ALLIANZ_POLICY_COMMAND)
  private readonly create_allianz_policy_command!: CreateAllianzPolicyCommand;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_CUSTOMER_DOCUMENTS_QUERY)
  private readonly search_customer_documents_query!: SearchCustomerDocumentsQuery;

  readonly i18n_namespace = 'components.contract-savings.almost-completed';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  readonly error_policy = i18n.tc('components.contract-savings.allianz.error_on_policy_creation');

  readonly investment_provider_name = 'allianz';

  readonly step_name = 'confirm_contribution_info';

  private readonly customer_id = sessionStorage.getItem('user_id');

  exists_step = false;

  investment_provider_id = '';

  is_loading = true;

  small_screen: null | boolean = null;

  internal_step = 1;

  policy_entiy: AllianzPolicyEntity = {
    policy_id: '',
    full_name: '',
    account_number: '',
    status_account_file_id: '',
  }

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {
      confirmed_collection_info: false,
    },
  };

  get person() {
    return this.get_person_query.execute();
  }

  get customer_documents() {
    return this.search_customer_documents_query.execute();
  }

  get investmentProviders() {
    return this.search_investment_providers_query.execute();
  }

  get steps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
    }
  };

  getFileID = async () => {
    const documents = await this.customer_documents;
    const account_file = documents.find(
      (document) => document.document_type.name === 'allianz_account_status',
    );
    if (account_file) {
      return account_file.file.id;
    }
    return '';
  }

  searchStep = (step_name: string, on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === step_name,
    );
    if (search_step) {
      return search_step;
    }
    return null;
  };

  fillPolicyInfo = async () => {
    const steps = await this.steps;
    const account_information_step = this.searchStep('account_information', steps);
    const full_name = `${this.person.name} ${this.person.last_name} ${this.person.second_last_name}`;
    const account_file_id = await this.getFileID();
    const policy_id = this.customer_id ? this.customer_id : this.person.id;
    this.policy_entiy = {
      policy_id,
      account_number: account_information_step?.payload.account_number,
      full_name,
      status_account_file_id: account_file_id,
    };
  }

  createPolicy = async () => {
    try {
      this.is_loading = true;
      await this.create_allianz_policy_command.execute(this.policy_entiy);
    } catch {
      this.message_notifier.showErrorNotification(this.error_policy);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  async saveStep() {
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload = {
        confirmed_collection_info: true,
      };
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  initialize = async () => {
    this.my_investment_allianz_on_boarding_status_subscription.execute(this.customer_id!);
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);

    const steps = await this.steps;
    await this.verifyStep(steps);

    await this.fillPolicyInfo();

    this.is_loading = false;
  }
}
